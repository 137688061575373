<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="offer">Modifica offerta: {{offer?.title}}</span>
    <span *ngIf="!offer">Nuova offerta</span>
  </span>
  <span *ngIf="offer">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteOffer()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="offerForm" novalidate>
    <div class="u-flex-full">
      <!-- <mat-form-field class="u-full-width">
        <mat-label>Business Opportunity</mat-label>
        <app-business-opportunity-field formControlName="businessOpportunity"></app-business-opportunity-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field> -->
      <mat-form-field class="u-full-width">
        <mat-label>Versione</mat-label>
        <input matInput formControlName="version" type="number" readonly>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Titolo</mat-label>
        <input matInput formControlName="title" type="text">
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="date" placeholder="Data" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <input matInput placeholder="Valore Totale" formControlName="totalAmount" type="number" min="0" forceNumber>
        <span class="prefix" matPrefix>€</span>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-select placeholder="Probabilità" formControlName="probability">
          <mat-option *ngFor="let probability of probabilities" [value]="probability">
            {{probability | offerProbability}}
          </mat-option>
        </mat-select>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-select placeholder="Tipologia Spesa" formControlName="expenseType">
          <mat-option *ngFor="let type of expenseTypes" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Costo € / km" formControlName="kmCost" type="number" min="0" forceNumber>
        <span class="prefix" matPrefix>€</span>
      </mat-form-field>
      <mat-form-field class="u-full-width" *ngIf="offerForm?.value?.businessOpportunity">
        <mat-label>Commessa</mat-label>
        <app-project-field formControlName="project" [projectFilters]="projectFilters" [showPromoFilter]="false"
          [showClientFilter]="false" [canSelectClosed]="false"></app-project-field>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Note</mat-label>
        <textarea matInput formControlName="notes" rows="3"></textarea>
      </mat-form-field>
    </div>

    <mat-card>
      <div class="u-flex-full">
        <h4>Distribuzione giornate</h4>
        <h4>
          <span>Valore stimato: {{estimatedValue | customCurrency}}&nbsp;€</span><br />
          <ng-container *ngIf="days">
            <span>Totale giornate: {{days}}</span><br />
            <span>Valore a giornata: {{(days ? estimatedValue / days : 0) | customCurrency}}&nbsp;€</span><br />
          </ng-container>
        </h4>
      </div>
      <app-days-distribution-field [form]="daysDistributionCtrls" [daysDistributions]="offer?.daysDistributions"
        [reset$]="reset$"></app-days-distribution-field>
    </mat-card>

    <mat-accordion multi="true">
      <!-- <mat-expansion-panel>
        <mat-expansion-panel-header>
          Distribuzione giornate
        </mat-expansion-panel-header>
        <app-days-distribution-field [form]="daysDistributionCtrls" [daysDistributions]="offer?.daysDistributions" [reset$]="reset$"></app-days-distribution-field>
        <div class="read-only-fields">
          <div class="u-flex-full">
            <mat-form-field class="u-full-width">
              <mat-label>Giornate</mat-label>
              <input matInput formControlName="days" readonly>
              <mat-hint>Campo in sola lettura</mat-hint>
            </mat-form-field>

            <mat-form-field class="u-full-width">
              <mat-label>Valore a giornata</mat-label>
              <input matInput formControlName="valuePerDay" readonly>
              <span class="prefix" matPrefix>€</span>
              <mat-hint>Campo in sola lettura</mat-hint>
            </mat-form-field>

            <mat-form-field class="u-full-width">
              <mat-label>Valore stimato </mat-label>
              <input matInput formControlName="estimatedValue" readonly>
              <span class="prefix" matPrefix>€</span>
              <mat-hint>Campo in sola lettura</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel> -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Allegati
        </mat-expansion-panel-header>
        <h4 class="u-center-text" *ngIf="!offer">Salvare l'offerta per poter inserire allegati</h4>
        <ng-container *ngIf="offer">
          <app-offer-attachments [filters]="filters"></app-offer-attachments>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- {{offer?.attachments | json}} -->
  </form>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="offerForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="offerForm.pristine || !offerForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>