<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    Filtra
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Data</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Inizio" formControlName="start">
          <input matEndDate placeholder="Fine" formControlName="end">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Cliente</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Commessa</mat-label>
        <app-project-field formControlName="project"></app-project-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Professionista</mat-label>
        <app-user-field formControlName="user"></app-user-field>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Promozione</mat-label>
        <mat-select formControlName="promo">
          <mat-option value="">-- Tutte --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Tipologia</mat-label>
        <mat-select formControlName="types" [multiple]="true">
          <mat-option value="Attività">Attività</mat-option>
          <mat-option value="Spesa">Spesa</mat-option>
          <mat-option value="Bonus">R&D</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Pagabile</mat-label>
        <mat-select formControlName="accountable">
          <mat-option value="">-- Tutte --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Fatturato</mat-label>
        <mat-select formControlName="invoiced">
          <mat-option value="">-- Tutte --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Pagato</mat-label>
        <mat-select formControlName="paid">
          <mat-option value="">-- Tutte --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Numero fattura</mat-label>
        <input matInput formControlName="invoiceNumber" type="text">
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Data fattura</mat-label>
        <mat-date-range-input [rangePicker]="invoiceDate">
          <input matStartDate placeholder="Inizio" formControlName="invoiceDateStart">
          <input matEndDate placeholder="Fine" formControlName="invoiceDateEnd">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="invoiceDate"></mat-datepicker-toggle>
        <mat-date-range-picker #invoiceDate></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Data pagamento fattura professionista</mat-label>
        <mat-date-range-input [rangePicker]="invoicePaymentDate">
          <input matStartDate placeholder="Inizio" formControlName="invoicePaymentDateStart">
          <input matEndDate placeholder="Fine" formControlName="invoicePaymentDateEnd">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="invoicePaymentDate"></mat-datepicker-toggle>
        <mat-date-range-picker #invoicePaymentDate></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <!-- <mat-form-field floatLabel="always" class="u-full-width">
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="invoiceDate" placeholder="Data fattura" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
      </mat-form-field> -->

      <!-- <mat-form-field floatLabel="always" class="u-full-width">
        <input matInput [matDatepicker]="fromDatePicker2" formControlName="invoicePaymentDate"
          placeholder="Data pagamento fattura" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker2"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker2></mat-datepicker>
      </mat-form-field> -->


    </div>

    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">Filtra</button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>