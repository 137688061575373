import { AbstractControl } from '@angular/forms';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

import { Activity, ActivityDTO } from './activity.model';
import { Base, BaseDTO } from './base.model';
import { BonusLine, BonusLineDTO } from './bonus_line.model';
import { Expense, ExpenseDTO } from './expense.model';
import { Professional, ProfessionalDTO } from './professional.model';
import { Project, ProjectDTO } from './project.model';
import { User } from './user.model';


export interface CostLineFilters {
  user_id?: number,
  start?: string,
  end?: string,
  client_id?: number,
  project_id?: number,
  invoice_number?: string,
  invoice_date_start?: string,
  invoice_date_end?: string,
  invoice_payment_date_start?: string,
  invoice_payment_date_end?: string,
  promo?: boolean
  invoiced?: boolean
  paid?: boolean
  accountable?: boolean
  types?: string[]
}

export interface CostLineDTO extends BaseDTO {
  date: string;
  description?: string;
  amount: number;
  invoice_number?: string;
  invoice_date?: string;
  invoice_payment_date?: string;

  activities?: ActivityDTO[];
  expenses?: ExpenseDTO[];
  bonus_lines?: BonusLineDTO[];
  project?: ProjectDTO;
  professional?: ProfessionalDTO;

  accountable: boolean;
  type: string;
}

export class CostLine extends Base {
  date: Date;
  description?: string;
  amount: number;
  selected: boolean;
  type: string;
  invoiceNumber?: string;
  invoiceDate?: Date;
  invoicePaymentDate?: Date;

  activities?: Activity[];
  expenses?: Expense[];
  bonusLines?: BonusLine[];
  project?: Project;
  professional?: Professional;

  accountable: boolean;

  constructor(source: CostLineDTO) {
    super(source);
    if (source) {
      this.date = new Date(source.date);
      this.description = source.description;
      this.amount = source.amount;
      this.selected = false;
      this.invoiceNumber = source.invoice_number;
      if (source.invoice_date) {
        this.invoiceDate = new Date(source.invoice_date);
      }
      if (source.invoice_payment_date) {
        this.invoicePaymentDate = new Date(source.invoice_payment_date);
      }

      if (source.activities?.length > 0) {
        this.activities = source.activities.map(dto => new Activity(dto));
      }
      if (source.expenses?.length > 0) {
        this.expenses = source.expenses.map(dto => new Expense(dto));
      }
      if (source.bonus_lines?.length > 0) {
        this.bonusLines = source.bonus_lines.map(dto => new BonusLine(dto));
      }
      this.type = source.type;
      if (source.project) {
        this.project = new Project(source.project);
      }
      if (source.professional) {
        this.professional = new Professional(source.professional);
      }
      this.accountable = source.accountable;
    }
  }

  toDTO(): CostLineDTO {
    let result: CostLineDTO = <CostLineDTO>super.toDTO();
    result.date = formatDateForBackend(this.date);
    result.description = this.description;
    result.invoice_number = this.invoiceNumber;
    result.invoice_date = formatDateForBackend(this.invoiceDate);
    result.invoice_payment_date = formatDateForBackend(this.invoicePaymentDate);
    result.professional = this.professional ? this.professional.toDTO() : null;
    result.project = this.project ? this.project.toDTO() : null;
    result.amount = this.amount;
    result.accountable = this.accountable;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: CostLine) {
    const formModel = formGroup.value;
    let costLine: CostLine = new CostLine(null);
    costLine.date = formModel.date;
    costLine.amount = formModel.amount;
    costLine.description = formModel.description;
    costLine.invoiceNumber = formModel.invoiceNumber;
    costLine.invoiceDate = formModel.invoiceDate;
    costLine.invoicePaymentDate = formModel.invoicePaymentDate;
    costLine.accountable = formModel.accountable;

    if (original) {
      costLine.id = original.id;
    }
    return costLine;
  }

  setSelected() {
    this.selected = !this.selected;
  }

  // get professionals(): Professional[] {
  //   if(this.activities?.length) {
  //     return this.activities.map(a => a.professional);
  //   }
  //   if(this.expenses?.length) {
  //     return this.expenses.map(e => e.professional);
  //   }
  //   return [];
  // }

  get invoiceable(): boolean {
    return this.accountable && !this.invoicePaymentDate && !this.invoiceDate;
  }

  get payable(): boolean {
    return this.accountable && !this.invoicePaymentDate && !!this.invoiceDate;
  }

  get accountableCancellable(): boolean {
    return !this.invoiceDate && !this.invoiceNumber && !this.invoicePaymentDate;
  }

  get user(): User {
    if (this.professional) {
      return this.professional.user;
    }
    if (this.bonusLines?.length) {
      return this.bonusLines[0].user;
    }
    return null;
  }

  get readableType(): string {
    if (this.type === 'Bonus') {
      return 'R&D';
    }
    return this.type;
  }

}
