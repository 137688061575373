<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    Filtra
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Ricerca libera</mat-label>
        <input type="text" matInput formControlName="search">
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Data inizio commessa</mat-label>
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="start" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="u-full-width" floatLabel="always">
        <mat-label>Data fine commessa </mat-label>
        <input matInput [matDatepicker]="fromDatePicker2" formControlName="end" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker2"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker2></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="u-full-width" *ngIf="showPromoFilter">
        <mat-label>Promozione</mat-label>
        <mat-select formControlName="promotion">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width" *ngIf="showClientFilter">
        <mat-label>Cliente</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Professionista</mat-label>
        <app-user-field formControlName="professional"></app-user-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Archiviate</mat-label>
        <mat-select formControlName="trashed">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Utente che ha creato la commessa</mat-label>
        <app-user-field formControlName="creator"></app-user-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Utente che per ultimo ha modificato la commessa</mat-label>
        <app-user-field formControlName="modifier"></app-user-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Chiuse</mat-label>
        <mat-select formControlName="closed">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">Filtra</button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>
