import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BusinessOpportunity } from 'src/app/commons/models/business-opportunity.model';
import { DaysDistribution } from 'src/app/commons/models/days-distribution.model';
import { OfferAttachmentFilters } from 'src/app/commons/models/offer-attachment.model';
import { EXPENSES, ExpenseType, Offer, PROBABILITIES } from 'src/app/commons/models/offer.model';
import { ProjectFilters } from 'src/app/commons/models/project.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as OfferActions from 'src/app/store/actions/offer.actions';
import { AppState } from 'src/app/store/reducers';
import * as BusinessOpportunitySelectors from 'src/app/store/selectors/business-opportunity.selectors';
import * as OfferSelectors from 'src/app/store/selectors/offer.selectors';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _offerId: number;
  private _offer: Offer;
  reset$ = new Subject<void>();
  projectFilters: ProjectFilters;
  filters: OfferAttachmentFilters;


  expenseTypes: ExpenseType[] = EXPENSES;
  probabilities = PROBABILITIES;
  offerVersion: number;

  businessOpportunity: BusinessOpportunity;
  days: number = 0;
  estimatedValue: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  offerForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.store$.pipe(select(OfferSelectors.getcurrentOffer), takeUntil(this.unsubscribe$)).subscribe(offer => {
      this.offer = offer;
    });
    this.store$.pipe(select(OfferSelectors.getOfferVersion), takeUntil(this.unsubscribe$)).subscribe(v => {
      this.offerVersion = v;
      this.ngOnChanges();

    });
    this.store$.pipe(select(BusinessOpportunitySelectors.getBusinessOpportunity), takeUntil(this.unsubscribe$), map(dto => dto ? new BusinessOpportunity(dto) : null)).subscribe(bo => {
      this.businessOpportunity = bo;
    });
  }

  ngOnInit() {
    this.loadOfferVersion();
    if (this.data) {
      this.offer = this.data.offer;
      this.filters = {
        offer_id: this.offer ? this.offer.id : null
      }
    }

    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadOfferVersion() {
    if (this.businessOpportunity)
      this.store$.dispatch(OfferActions.offerVersion({ id: this.businessOpportunity.id }));
  }

  private _createForm() {
    let group = {
      version: ['', Validators.required],
      businessOpportunity: ['', Validators.required],
      date: ['', Validators.required],
      title: ['', Validators.required],
      totalAmount: ['', Validators.required],
      probability: ['', Validators.required],
      notes: [''],
      expenseType: ['', Validators.required],
      kmCost: [''],
      project: [''],
      // days: [''],
      // valuePerDay: [''],
      // estimatedValue: [''],
      daysDistributions: this.fb.array([]),
    }

    this.offerForm = this.fb.group(group);
    this.offerForm.get('businessOpportunity').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((businessOpportunity: BusinessOpportunity) => {
      if (businessOpportunity && businessOpportunity.client) {

        this.projectFilters = {
          client: businessOpportunity.client,
          promotion: false,
          excludeWithOffer: true
        }
      }
    });

    this.offerForm.get("daysDistributions").valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((array) => {
      if (array && array.length > 0) {
        let totalDays: number = 0;
        array.forEach((daysDistribution: DaysDistribution) => {
          totalDays += daysDistribution.days;
        });
        this.days = totalDays;
        //   if(totalDays) {
        //     this.offerForm.patchValue({days : totalDays})
        //  }
      } else {
        // this.offerForm.patchValue({days : 0})
        this.days = 0;
      }

    });
    this.offerForm.get("totalAmount").valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      // if(value && this.days) {
      //   // this.offerForm.patchValue({valuePerDay : Math.round(value/this.offerForm.get('days').value)})
      //   this.valuePerDay = Math.round(value / this.days);
      // } else{
      //   // this.offerForm.patchValue({valuePerDay : 0})
      //   this.valuePerDay = 0;
      // }
      if (value && this.offerForm.get('probability').value) {
        // this.offerForm.patchValue({estimatedValue : Math.round((value * this.offerForm.get('probability').value)/100)})
        this.estimatedValue = Math.round((value * this.offerForm.get('probability').value) / 100);
      } else {
        this.estimatedValue = 0;
        // this.offerForm.patchValue({estimatedValue : 0})
      }
    });
    this.offerForm.get("probability").valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      if (value && this.offerForm.get('totalAmount').value) {
        this.offerForm.patchValue({ estimatedValue: Math.round((this.offerForm.get('totalAmount').value * value) / 100) })
        this.estimatedValue = Math.round((this.offerForm.get('totalAmount').value * value) / 100);
      } else {
        // this.offerForm.patchValue({estimatedValue : 0})
        this.estimatedValue = 0;
      }
    });
    //  this.offerForm.get("days").valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
    //   if(value && this.offerForm.get('totalAmount').value) {
    //     this.offerForm.patchValue({valuePerDay : Math.round(this.offerForm.get('totalAmount').value/value)})
    //   } else{
    //     this.offerForm.patchValue({valuePerDay : 0})
    //   }
    //  });

  }


  get offer(): Offer {
    return this._offer;
  }

  set offer(offer: Offer) {
    this._offer = offer;
    this.ngOnChanges();
  }
  private ngOnChanges() {
    if (this.offerForm) {
      this.offerForm.reset();
      this._initFormValues(this.offer);
    }
  }

  private _initFormValues(offer?: Offer) {
    if (offer) {
      this.offerForm.patchValue({
        version: offer.version,
        businessOpportunity: offer.businessOpportunity,
        date: offer.date,
        title: offer.title,
        totalAmount: offer.totalAmount,
        probability: offer.probability,
        notes: offer.notes,
        expenseType: offer.expenseType,
        kmCost: offer.kmCost,
        project: offer.project,
      });
    } else {
      this.offerForm.patchValue({
        date: new Date(),
        businessOpportunity: this.businessOpportunity,
        version: this.offerVersion
      });
      this.offerForm.markAllAsTouched();
    }
  }


  private _prepareSaveOffer(): Offer {
    let savingOffer: Offer = Offer.fromFormGroup(this.offerForm, this.offer);
    return savingOffer;
  }
  get daysDistributionCtrls(): FormArray {
    return this.offerForm.get('daysDistributions') as FormArray;
  }

  save() {
    let unsavedEntity = this._prepareSaveOffer();
    this.store$.dispatch(OfferActions.saveOffer({ offer: unsavedEntity }));
  }

  close() {
    if (this.offerForm.pristine) {
      this.store$.dispatch(OfferActions.closeOfferDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(OfferActions.closeOfferDialog())
          }
        });
    }
  }

  deleteOffer() {
    if (this.offer) {
      this.store$.dispatch(OfferActions.deleteOffer({ offer: this.offer.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
